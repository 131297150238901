import { LOGOUT, SET_AUTHENTICATED, SET_WRONG_EMAIL_OR_PASSWORD,GET_ROLES,SET_ROLES } from '../types/Session';
import User from "../../models/User";

export const initialState = {
  authenticated: false,
  user: User,
  rolesLoading: false,
  roles: [],
  error: undefined,
};

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload !== undefined,
        user: action.payload,
      };
    case SET_WRONG_EMAIL_OR_PASSWORD:
      return {
        ...state,
        authenticated: false,
        error: action.payload,
      }

      case GET_ROLES:
        return {
          ...state,
          rolesLoading: true,
        }
      case SET_ROLES:
        return {
          ...state,
          roles: action.payload.roles,
          rolesLoading: false,
        };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
  
};
