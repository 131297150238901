import {
  GET_BUSINESS_TRANSACTION,
  GET_EMPLOYEE_TRANSACTION,
  SET_BUSINESS_TRANSACTION,
  SET_EMPLOYEE_TRANSACTION
} from '../types/Transactions';
import { getTransactions } from "../../services/transactions";

export const getEmployeeTransactions = (businessId, employeeId, page) => (dispatch) => {
  dispatch({ type: GET_EMPLOYEE_TRANSACTION });
  getTransactions(businessId, page, 10, employeeId).subscribe({
    next: (transactions) =>
      dispatch({ type: SET_EMPLOYEE_TRANSACTION, payload: transactions })
  });
}

export const getBusinessTransactions = (businessId, page) => (dispatch) => {
  dispatch({ type: GET_BUSINESS_TRANSACTION });
  getTransactions(businessId, page, 10).subscribe({
    next: (transactions) =>
      dispatch({ type: SET_BUSINESS_TRANSACTION, payload: transactions })
  });
}
