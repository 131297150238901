import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import fr from './locales/fr';
import dayjs from "dayjs";

require('dayjs/locale/fr');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'fr',
    resources: {
      en: en,
      fr: fr
    }
  }).resolve();

dayjs.locale(i18n.language);

export default i18n;
