import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { getBusinessEmployees, getUserBusiness, selectEmployee } from "../../store/actions/Business";
import type { Employee } from "../../models/Employee";
import NeatcarButton from "../../components/NeatcarButton";
import NeatcarModal from "../../components/NeatcarModal";
import { addEmployee } from "../../services/business";
import { pushNotification } from "../../store/actions/Notifications";
import { Notification, SUCCESS } from "../../models/Notification";
import { useNavigate } from "react-router-dom";

const Team = () => {
  const [showAddMember, setShowAddMember] = useState(false);
  const [email, setEmail] = useState();
  const [userId, setUserId] = useState();
  const [addFormError, setAddFormError] = useState();

  const { t } = useTranslation('teams');
  const dispatch = useDispatch();
  const { business, employees } = useSelector(store => store.business);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserBusiness());
  }, [dispatch]);

  useEffect(() => {
    if (business?.id) {
      dispatch(getBusinessEmployees(business.id));
    }
  }, [business, dispatch]);

  const select = (employee: Employee) => {
    dispatch(selectEmployee(employee));
    navigate('/team/member');
  }

  const clearForm = () => {
    setEmail(undefined);
    setUserId(undefined);
    setShowAddMember(false);
    setTimeout(() => setAddFormError(undefined), 500);
  }

  const addMember = () => {
    if ((!email && !userId) || (!!email && !!userId)) {
      setAddFormError(t('empty'));
      return;
    }

    addEmployee(business.id, email, userId ? parseInt(userId) : null).subscribe((res) => {
      if (!res.success) {
        setAddFormError(t(`errors.${res.error.code}`));
      } else {
        const addedUser = res.data.employee.user;
        setShowAddMember(false);
        dispatch(getBusinessEmployees(business.id));
        dispatch(pushNotification(new Notification(SUCCESS, t('addSuccessTitle', { firstName: addedUser.firstName }), t('addSuccessMessage', { firstName: addedUser.firstName }))));
      }
    });

  }

  return (
    <>
      <div>
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{business?.name}</h3>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <NeatcarButton
              onClick={() => setShowAddMember(true)}
              type="button"
              title={t('addMember')}/>
          </div>
        </div>

        <ul className="divide-y divide-gray-200">
          {employees.map((employee): Employee => (
            <li key={employee.user.email}>
              <div onClick={() => select(employee)} className="block hover:bg-gray-50 cursor-pointer">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p
                          className="text-sm font-medium text-neatcar truncate">{`${employee.user.firstName} ${employee.user.lastName}`}</p>
                        <p className="mt-2 flex items-center text-sm text-neatcarGray">
                          <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-neatcarGray" aria-hidden="true"/>
                          <span className="truncate">{employee.user.email}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon className="h-5 w-5 text-neatcarGray" aria-hidden="true"/>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <NeatcarModal
        visible={showAddMember}
        onClose={() => clearForm()}
        actionText={t('addToTeam')}
        cancelText={t('cancel')}
        onValidate={() => addMember()}>
        <>
          <div className="w-full text-neatcar text-xl">
            {t('addMember')}
          </div>
          <div className="mt-12">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {t('email')}
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                id="email"
                className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="you@example.com"
                aria-describedby="email-description"
              />
            </div>
          </div>
          <div className="my-4 text-neatcarGray">
            {t('or')}
          </div>
          <div className="mb-12">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {t('userId')}
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setUserId(e.target.value)}
                type="text"
                name="userId"
                id="userId"
                className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="12345"
                aria-describedby="userId-description"
              />
            </div>
            <p className="mt-2 text-sm text-gray-500" id="email-description">
              {t('userIdHelp')}
            </p>
          </div>
          <div className="text-center text-error text-xs">
            {addFormError ?? ''}
          </div>
        </>
      </NeatcarModal>
    </>
  )
};

export default Team;
