import { combineReducers } from '@reduxjs/toolkit';
import Session from './Session';
import Business from './Business';
import Notifications from './Notifications';
import Transactions from './Transactions';
import Support from './Support';

export default combineReducers({
  session: Session,
  business: Business,
  notifications: Notifications,
  transactions: Transactions,
  support : Support,
});
