import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { Label } from "components/ui/label"

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool
]);


class TripleToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchPosition: props.switchPosition,
      animation: null
    };
  }

  getSwitchAnimation = (value) => {
    const { switchPosition } = this.state;
    let animation = null;
    if (value === "center" && switchPosition === "left") {
      animation = "left-to-center";
    } else if (value === "right" && switchPosition === "center") {
      animation = "center-to-right";
    } else if (value === "center" && switchPosition === "right") {
      animation = "right-to-center";
    } else if (value === "left" && switchPosition === "center") {
      animation = "center-to-left";
    } else if (value === "right" && switchPosition === "left") {
      animation = "left-to-right";
    } else if (value === "left" && switchPosition === "right") {
      animation = "right-to-left";
    }
    this.props.onChange(value);
    this.setState({ switchPosition: value, animation });
  };

  render() {
    const { labels,title } = this.props;

    return (
      <div>
        <Label>{title}</Label>
      <div className="	 main-container">
        <div
          className={`switch ${this.state.animation} ${this.state.switchPosition}-position`}
        ></div>
        <input
          
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id={"left"+title}
          type="radio"
          value="left"
        />
        <label
          className={`left-label flex flex-1 justify-center items-center ${
            this.state.switchPosition === "left" && "black-font"
          }`}
          htmlFor={"left"+title}
        >
          <h4>{labels.left.title}</h4>
        </label>

        <input
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id={"center"+title}
          type="radio"
          value="center"
        />
        <label
          className={`center-label flex flex-1 justify-center items-center ${
            this.state.switchPosition === "center" && "black-font"
          }`}
          htmlFor={"center"+title}
        >
          <h4 className="">{labels.center.title}</h4>
        </label>

        <input
        
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id={"right"+title}
          type="radio"
          value="right"
        />
        <label
          className={`right-label flex flex-1 justify-center items-center ${
            this.state.switchPosition === "right" && "black-font"
          }`}
          htmlFor={"right"+title}
        >
          <h4>{labels.right.title}</h4>
        </label>
      </div>
      </div>
    );
  }
}



export default TripleToggleSwitch;
