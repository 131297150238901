import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { CodeIcon, PencilIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import InputMask from "react-input-mask";
import NeatcarButton from "../../components/NeatcarButton";
import Paginator from "../../components/Paginator";
import TransactionRow from "../../components/TransactionRow";
import LoadingIndicator from "../../components/LoadingIndicator";
//import visa from '../../assets/images/visa.png';
//import mastercard from '../../assets/images/mastercard.png';
import { getCurrentCard, setCreditCardLoading } from "../../store/actions/Business";
import { updateCard } from "../../services/business";
import { pushNotification } from "../../store/actions/Notifications";
import { ERROR, Notification, SUCCESS } from "../../models/Notification";
import { getCSV } from "../../services/transactions";
import fileDownload from 'js-file-download';
import { StationsTable } from "../../table/station/StationsTable"
import { Store, columns } from "../../table/station/columns"


import { getStoresData, getRemainingCarwashData, getCarwashTypeData } from 'store/actions/Support';

const Stations = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [page, setPage] = useState(1);
  const [csvLoading, setCsvLoading] = useState(false);
  const { t } = useTranslation(['billing', 'transaction']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stores = useSelector(store => store.support);
  const [tableData, setTableData] = useState([]);


  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const fetchedData = await getData();
      setData(fetchedData);
      console.log(data)
    }
    fetchData();
  }, []);

  useEffect(() => {

    dispatch(getStoresData());
 

  }, [dispatch, navigate, page]);

 

  useEffect(() => {
    
    if(stores && stores.storesLoading == false){
    setTableData(stores.stores)

    }

  }, [stores]);

  useEffect(() => {
    dispatch(getCurrentCard());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(setCreditCardLoading(true));
    updateCard({
      name: data.name,
      number: data.cardNumber.replace(/\s/g, ''),
      exp_month: data.expirationDate.substring(0, 2),
      exp_year: `20${data.expirationDate.substring(3, 5)}`,
      cvv: data.cvv
    }).subscribe({
      next: () => {
        dispatch(getCurrentCard());
        dispatch(pushNotification(new Notification(SUCCESS, t('billing:modifiedCreditCardSuccessTitle'), t('billing:modifiedCreditCardSuccessMessage'))));
      },
      error: (err) => {
        dispatch(setCreditCardLoading(false));
        dispatch(pushNotification(new Notification(ERROR, t('billing:modifiedCreditCardErrorTitle'), t('billing:modifiedCreditCardErrorMessage'))));
      }
    });
  }

  async function getData() {
    // Fetch data from your API here.
    return [
      {
        id: '728ed52f',
        amount: 100,
        status: 'pending',
        email: 'm@example.com',
      },
      // ...
    ];
  }




  return (
    <div className="flex-1 flex-row gap-x-8">





      <StationsTable columns={columns} data={tableData} />


    </div>
  )
};

export default Stations;
