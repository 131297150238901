"use client"

import { ColumnDef, FilterFn } from "@tanstack/react-table"
import { ArrowUpDown, MoreHorizontal } from "lucide-react"
import { Button } from "components/ui/button"
import { useState } from "react"; 
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog"
import { table } from "components/ui/table";
import { Checkbox } from "components/ui/checkbox";
import { getStoresData,getRemainingCarwashData, getCarwashTypeData, modifyAmountBuying} from 'store/actions/Support';


const FilterFunctionAlert = (row, id, filterValue) => {

  switch (filterValue) {
    case "left":
      return true

    case "center":
      return (row.getValue(id) == "")

    case "right":
      return (row.getValue(id) != "")

    default:
      return true;
  }

}

const FilterFunctionLocal = (row, id, filterValue) => {
  switch (filterValue) {
    case "left":
      return true

    case "center":
      return (row.getValue(id) == "false")

    case "right":
      return (row.getValue(id) == "true")

    default:
      return true;
  }

}
const FilterFunctionRoutes = (row, id, filterValue) => {
const found = row.original.routes.filter(item =>item.routeName.includes(filterValue))

  if(found!=""){
    return true
  }else{
    return false
  }
  

}




 export const columns = (stores,dispatch) => {
  
  
  
  return [
  {
    id: "select",
    header: ({ table }) => {
      return (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => {
            table.toggleAllPageRowsSelected(!!value);
          }}
        />
      );
    },
    cell: ({ row }) => {
      return (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            row.toggleSelected(!!value);
          }}
        />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },

  {
    id:'address',
    enableHiding:false,
    accessorKey: "address",
    header: "Address",
  },
  {
    id:'routes',
    accessorKey: "associatedRoute",
    header: "Route(s)",
    cell: info => {
      const value = info.getValue() ; //casting may not be required here
      return <>{value.map(v => <p key={v.id}>{v.routeName}</p>)}</>
    },
    filterFn: FilterFunctionRoutes
  },
  
  {
    accessorKey: "carwashTypes",
    id:"amount",
    header: "Carwashes",
    cell: info => {
      const value = info.getValue() ; //casting may not be required here
      return <>{value.map(v => <p key={"amount"+v.id}>{v.nameEn}: {v.amount}</p>)}</>
    }
  },
  {
    accessorKey: "carwashTypes",
    id:"price",
    header: "Price Sold",
    cell: info => {
      const value = info.getValue() ; //casting may not be required here
      return <>{value.map(v => <p key={"price"+v.id}>({v.nameEn}: {v.price/100}$ , Pro {v.pricePro/100}$)</p>)}</>
    }
  },
  {
    id:"BuyingAmount",
    enableHiding:false,
    accessorKey: "BuyingAmount",
    header: "BuyingAmount",
    cell: ({  row ,table}) => (
      <input
        type="text"
        value={ 
          row.original
          //stores.currentBuying.find(object => object.storeId == row.original.id && object.routeName == table.getColumn('routes')?.getFilterValue())?.amountBuying || 0
        }
        onChange={(e) => { dispatch(modifyAmountBuying(row.original.id, table.getColumn('routes')?.getFilterValue(), e.target.value));
        }}
      />
    )
  },
  {
    id:"alertEn",
    accessorKey: "alertEn",
    header: "Alert",
    filterFn: FilterFunctionAlert
  },
  {
    id:"rating",
    accessorKey: "rating",
    header: ({ column }) => {
      return (
        <Button
          
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Rating
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    }
  }
  ,
  
  {
    id:"active",
    accessorKey: "active",
    header: "Active?",
    filterFn: FilterFunctionLocal


  },
  {
    enableHiding:false,
    id: "actions",
    cell: ({row,table}) => {
      const rowData = row.original

      return (
        
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex">
            <Button variant="ghost" className="flex h-8 w-8 p-0 justify-end">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => table.options.meta }>Edit</DropdownMenuItem>

            <DropdownMenuSeparator />
            <DropdownMenuItem>View customer</DropdownMenuItem>
            <DropdownMenuItem>View payment details</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )

    }
  }
]
};

