import * as React from "react";
import LoadingIndicator from "./LoadingIndicator";

const NeatcarButton = (
  {
    disabled,
    loading,
    type,
    title,
    onClick = () => {},
    inverse = false,
    className,
  }) => {

  let style = inverse ? ' text-neatcar bg-white border-neatcar' : ' border-transparent text-white bg-neatcar';
  if (disabled) {
    style += ' opacity-30 hover:opacity-30';
  } else {
    style += ' hover:opacity-95'
  }
  if (className) {
    style += ` ${className}`;
  }

  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={"w-full h-10 flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neatcar whitespace-nowrap" + style}
    >
      {loading ? <LoadingIndicator/> : title}
    </button>
  );
}

export default NeatcarButton;
