import React from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../header/Header";
import { useSelector } from "react-redux";

const SecuredLayout = ({ layout = 1 ,fullwidth = false}) => {
  const { authenticated } = useSelector(store => store.session);
  let location = useLocation();

  if (!authenticated) {
    console.log(authenticated)
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const renderLayout = () => {

    if (!layout || layout === 1) {
      return (
        <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
          <Outlet/>
        </div>
      );
    }

    if (layout === 2) {
      return (
        <Outlet/>
      );
    }

  }

  return (
    <div className="min-h-full bg-gray-200">

        <Header/>

        <main className="-mt-32">
          
          <div className={fullwidth ?  "" : "max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8" }>
            {renderLayout()}
          </div>
        </main>
    </div>
  );
};

export default SecuredLayout;
