import AxiosInstance from "./api";
import { map, Observable } from "rxjs";
import { TransactionPage } from "../models/Page";
import i18n from '../i18n/index';

export function getStores (): Observable<string> {
 
  return AxiosInstance.get(`api/v1/store`)
    .pipe(
      map(res => res.data.data)
    );
}

export function getRemainingCarwash (): Observable<string> {
 
  return AxiosInstance.get(`api/v1/store/carwash/count`)
    .pipe(
      map(res => res.data.data)
    );
}

export function getCarwashType (): Observable<string> {
 
  return AxiosInstance.get(`api/v1/store/carwash/type`)
    .pipe(
      map(res => res.data.data)
    );
}


export function getReceipt (businessId, transactionId): Observable<string> {
  return AxiosInstance.get(`api/v1/business/transaction/receipt?businessId=${businessId}&transactionId=${transactionId}`)
    .pipe(
      map(res => res.data.data.receipt)
    );
}

export function getCSV (businessId, employeeId): Observable<string> {
  return AxiosInstance.get(`api/v1/business/transaction/csv?businessId=${businessId}${employeeId ? '&employeeId=' + employeeId : ''}`,
    {
      headers: {
        Language: i18n.language
      },
      responseType: 'blob',
    })
    .pipe(
      map(res => res.data)
    );
}