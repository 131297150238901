"use client"
import * as React from "react"

import { useState,useEffect } from "react"; // Import useState hook
import { CSVLink, CSVDownload } from "react-csv";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  ColumnDef,
  ColumnFiltersState,
  ColumnVisibility,
  RowSelectionState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table"

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover"

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select"



import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Checkbox } from "components/ui/checkbox"
import TripleToggleSwitch from "components/tripleToggle/triple"
import { SlidersHorizontal, TableProperties } from 'lucide-react';
import { addToCurrentBuying } from 'store/actions/Support';

import NeatcarButton from "../../components/NeatcarButton";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuCheckboxItem, DropdownMenuContent } from "components/ui/dropdown-menu";


  



export function RoutesTable({
  columns,
  data,
}) {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [csvLoading, setCsvLoading] = useState(false);

  const [rowSelection, setRowSelection] = useState({})
  const [sorting, setSorting] = React.useState([])
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnVisibility, setColumnVisibility] = useState({ 'alertEn': false, 'rating': false })
  const stores = useSelector(store => store.support);

  const dispatch = useDispatch();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    getFacetedUniqueValues: getFacetedUniqueValues(),


    state: {
      sorting,
      columnFilters,
      rowSelection,
      columnVisibility

    },


    meta: {
      isEditDialogOpen,
      onOpenCard: () => setIsEditDialogOpen(true)
    }
  })
  const labels = {
    left: {
      title: "All",
      value: "All"
    },
    right: {
      title: "Yes",
      value: "Yes"
    },
    center: {
      title: "No",
      value: "No"
    }
  };

  const localLabels = {
    left: {
      title: "All",
      value: "All"
    },
    right: {
      title: "Local",
      value: "Local"
    },
    center: {
      title: "Network",
      value: "Network"
    }
  };

  const csvData = [
    { Code: "123" }
  ];

  const headers = [
    { label: "Code", key: "Code" },
    { label: "Nombre", key: "Nombre" },
    { label: "Buyingprice", key: "Buyingprice" },
    { label: "ExpirationDate", key: "ExpirationDate" },
    { label: "NetworkId", key: "NetworkId" },
    { label: "StoreId", key: "StoreId" }

  ];

  useEffect(() => {
    
    if(stores && stores.storesLoading == false){
    //setTableData(stores.stores)
      console.log(stores.currentBuying)
    }

  }, [stores]);

  const uniqueRouteNames = React.useMemo(() => {

    const uniqueRouteNames = data.reduce((accumulator, item) => {
      item.routes.forEach(route => {
        if (!accumulator.includes(route.routeName)) {
          accumulator.push(route.routeName);
        }
      });
      return accumulator;
    }, []);

    //this set initially the select to the first route
    table.getColumn("routes").setFilterValue(uniqueRouteNames[0])
    return uniqueRouteNames;
  }, [data]);

  return (
    <div className="rounded-md border">




      <div className="flex justify-between p-3">
        <div className="flex ">
          <div className="max-w-sm pr-1">
            <Input

              placeholder="Search"
              value={table.getColumn('address')?.getFilterValue() || ""}
              onChange={(ev) => {
                table.getColumn('address')?.setFilterValue(ev.target.value)

              }}
            />
          </div>
          <div className="max-w-sm">

            <Select
              onValueChange={(e) => {table.getColumn("routes").setFilterValue(e) 
              table.setRowSelection([])
              console.log(e)}}
              value={table.getColumn("routes").getFilterValue()?.toString()}

            >
              <SelectTrigger className="w-[180px]">
                <SelectValue  />
              </SelectTrigger>
              <SelectContent>
                {uniqueRouteNames.map((value) => {
                  if (value) {
                    return (
                      <SelectItem key={value} value={value} >
                        {value}
                      </SelectItem>
                    );
                  }
                  return null;
                })}
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="flex">
          { Object.keys(rowSelection).length > 0 && (
            <NeatcarButton
              className="min-w-32"
              onClick={() => { dispatch(addToCurrentBuying(table.getSelectedRowModel().rows.map(object => object.original.routes.find(route => route.routeName == table.getColumn("routes").getFilterValue())))) }}
              loading={csvLoading}
              inverse
              type="button"
              title={'Add to buy'}
            />
          )}
          <div className="px-3">
            <Button variant="outline" className="pd-1">

              <CSVLink

                data={csvData}
                headers={headers}
                filename={"s"}
                target="_blank"
              >
                download csv
              </CSVLink>
            </Button>
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger>

              <TableProperties strokeWidth='1px'></TableProperties>

            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table.getAllColumns().filter(column => column.getCanHide()).map(column => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => {
                      column.toggleVisibility(!!value)
                    }}
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
            </DropdownMenuContent>

          </DropdownMenu>
          <div>
            <Popover >
              <PopoverTrigger className="p-2"><SlidersHorizontal strokeWidth='1px' /></PopoverTrigger>
              <PopoverContent>


                <div className="grid justify-items-stretch">

                  <div className="">
                    <TripleToggleSwitch
                      labels={labels}
                      title={"Alert"}
                      switchPosition={table.getColumn('alertEn')?.getFilterValue() || "left"}
                      onChange={(value) => {
                        table.getColumn('alertEn')?.setFilterValue(value)
                      }}
                    />
                  </div>






                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>

      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                style={row.original.routes != null ? {background: 'palegreen'} : {}}

                
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="flex items-center justify-end space-x-2 py-4 pr-8">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </div>
  )
}
