export  const routesHardCoded = [
    {
      storeId: 2727,
      routeName: "Montreal",
      position: 1

    },
    {
      storeId: 2521,
      routeName: "Montreal",
      position: 1
    },
    {
      storeId: 2521,
      routeName: "laval",
      position: 1
    }
  ]