import AxiosInstance from "./api";
import { catchError, map, Observable, of } from "rxjs";
import { type Employee } from "../models/Employee";
import { type Business } from "../models/Business";
import { Response } from "../models/Response";
import type { Card, CardUpdate } from "../models/Card";

export function getBusiness (): Observable<Business> {
  return AxiosInstance.get('api/v1/user/business')
    .pipe(
      map(res => res.data.data.business)
    );
}

export function getEmployees (businessId): Observable<Employee[]> {
  return AxiosInstance.get(`api/v1/business/employee?businessId=${businessId}`)
    .pipe(
      map(res => res.data.data.employees)
    );
}

export function addEmployee (businessId, userEmail, userId): Observable<Response> {
  return AxiosInstance.post('api/v1/business/employee', { businessId, userEmail, userId })
    .pipe(
      map(res => res.data),
      catchError(error => {
        return of(error.response.data);
      })
    );
}

export function remove (employeeId): Observable<Response> {
  return AxiosInstance.delete(`api/v1/business/employee`, { data: { employeeId } })
    .pipe(
      map(res => res.data.success)
    );
}

export function fetchCurrentCards (): Observable<Card[]> {
  return AxiosInstance.get(`api/v1/user/card`)
    .pipe(
      map(res => res.data.data.cards)
    );
}

export function updateCard (cardUpdate: CardUpdate): Observable<boolean> {
  return AxiosInstance.post(`api/v1/user/card`, cardUpdate)
    .pipe(
      map(res => res.data.success)
    );
}
