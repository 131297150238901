import { SELECT_EMPLOYEE, SET_BUSINESS, SET_CARD_LOADING, SET_CURRENT_CARD, SET_EMPLOYEES } from '../types/Business';
import { fetchCurrentCards, getBusiness, getEmployees, remove } from "../../services/business";
import type { Employee } from "../../models/Employee";
import type { Card } from "../../models/Card";

export const getUserBusiness = () => {
  return (dispatch) => {
    getBusiness().subscribe({
      next: (business) =>
        dispatch({ type: SET_BUSINESS, payload: business })
    });
  };
};

export const getBusinessEmployees = (businessId: number) => {
  return (dispatch) => {
    getEmployees(businessId).subscribe({
      next: (employees) =>
        dispatch({ type: SET_EMPLOYEES, payload: employees })
    });
  };
};

export const selectEmployee = (employee: Employee) => (dispatch) => {
    return dispatch({ type: SELECT_EMPLOYEE, payload: employee })
};

export const removeEmployee = (employee) => (dispatch) => {
  remove(employee.id).subscribe();
};

export const getCurrentCard = () => (dispatch) => {
  dispatch({ type: SET_CARD_LOADING, payload: true });
  fetchCurrentCards().subscribe({
    next: (cards: Card[]) =>
      dispatch({ type: SET_CURRENT_CARD, payload: cards.at(0) }),
    error: () => dispatch({ type: SET_CARD_LOADING, payload: false })
  });
};

export const setCreditCardLoading = (loading) => (dispatch) => {
  dispatch({ type: SET_CARD_LOADING, payload: loading });
}
