"use client"

import { ColumnDef, FilterFn } from "@tanstack/react-table"
import { ArrowUpDown, MoreHorizontal } from "lucide-react"
import { Button } from "components/ui/button"
import { useState } from "react"; 
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog"
import { table } from "console";
import { Checkbox } from "components/ui/checkbox";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type Store = {
  id: string
  amount: number
  address: string
  email: string
  rating: string
}
const FilterFunctionAlert = (row, id, filterValue) => {
  switch (filterValue) {
    case "left":
      return true

    case "center":
      return (row.getValue(id) == "")

    case "right":
      return (row.getValue(id) != "")

    default:
      return true;
  }

}

const FilterFunctionLocal = (row, id, filterValue) => {
  console.log(filterValue)
  switch (filterValue) {
    case "left":
      return true

    case "center":
      return (row.getValue(id) == false)

    case "right":
      return (row.getValue(id) == true)

    default:
      return true;
  }

}
export const columns: ColumnDef<Store>[] = [
  {
    id: "select",
    header: ({ table }) => {
      return (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => {
            table.toggleAllPageRowsSelected(!!value);
          }}
        />
      );
    },
    cell: ({ row }) => {
      return (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            row.toggleSelected(!!value);
          }}
        />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },

  {
    id:"Address",
    enableHiding:false,
    accessorKey: "address",
    header: "Address",
  },
  {
    id:'Carwashes',
    accessorKey: "carwashTypes",
    header: "Carwashes",
    cell: info => {
      const value = info.getValue() as any[]; //casting may not be required here
      return <>{value.map(v => <p key={v.id}>{v.nameEn}: {v.amount}</p>)}</>
    }
  },
  {
    id:'Alert',
    accessorKey: "alertEn",
    header: "Alert",
    filterFn: FilterFunctionAlert
  },
  {
    id:'Rating',
    accessorKey: "rating",
    header: ({ column }) => {
      return (
        <Button
          
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Rating
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      )
    }
  }
  ,
  {
    id:'Local Codes',
    accessorKey: "isMaintainedLocally",
    header: "Local Codes",
    filterFn: FilterFunctionLocal


  },
  {
    id:'Active',
    accessorKey: "active",
    header: "Active",
    


  },
  {
    id: "actions",
    enableHiding:false,
    cell: ({row,table}) => {
      const rowData = row.original

      return (
        
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex">
            <Button variant="ghost" className="flex h-8 w-8 p-0 justify-end">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => table.options.meta }>Edit</DropdownMenuItem>

            <DropdownMenuSeparator />
            <DropdownMenuItem>View customer</DropdownMenuItem>
            <DropdownMenuItem>View payment details</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )

    }
  }
]
