const en = {
  login: {
    title: 'Business account',
    subtitle: 'No business account yet?',
    salesLinkLabel: 'Contact us.',
    salesLink: 'https://neatcar.com/en/contact.php',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password?',
    signIn: 'Sign in',
    wrongCredentials: 'Wrong credentials',
    required: 'This field is required',
    wrongEmail: 'This email is not valid',
  },
  notFound: {
    error: '404 Error',
    lost: 'Uh oh! I think you’re lost.',
    subtitle: 'It looks like the page you’re looking for doesn\'t exist.',
    goBackHome: 'Go back home',
  },
  menu: {
    fleat: 'Fleat & Team',
    team: 'Team',
    billing: 'Billing',
    otherLanguage: 'Français',
    otherLanguageKey: 'fr',
    logout: 'Sign out',
    marketplace: 'Marketplace',
    marketplaceGiftTitle: 'Gift and experience',
    marketplaceGiftDesc: 'Offer Neatcar Premium washes as gifts to your clients with a personalized experience.',
    marketplaceDealershipTitle: 'Dealership',
    marketplaceDealershipDesc: 'Complete module to personalize a unique experience.',
    marketplaceStationTitle: 'Car wash station',
    marketplaceStationDesc: 'Add you car wash station in Neatcar and easily target thousands of car owner.',
    marketplaceAdCampaignTitle: 'Ad campaigns',
    marketplaceAdCampaignDesc: 'Are car owner your clients? Target them at the right time.',
    marketplaceSalesTitle: 'Want to talk with sales?',
    marketplaceSalesDesc: 'Reach us at: sales@neatcar.com'
  },
  teams: {
    addMember: 'Add a member',
    email: 'Email',
    or: 'Or',
    userId: 'User ID',
    userIdHelp: 'The user ID can be found on the profile page in the mobile app.',
    addToTeam: 'Add to team',
    cancel: 'Cancel',
    empty: 'You need to enter an email or a user ID',
    errors: {
      11: 'Make sure the email or user ID is valid',
      25: 'This user can not be added because he is already in your team.',
      26: 'You have reached the maximum amount of employee for your plan. Contact us at sales@neatcar.com if you want to upgrade your plan.'
    },
    addSuccessTitle: '{{firstName}} has been successfully added',
    addSuccessMessage: '{{firstName}} can now buy car wash code according to his permissions.'
  },
  member: {
    delete: 'Remove',
    deleteTitle: 'Remove account',
    deleteText: 'Are you sure you want to remove this account? All of the data will be permanently removed from our servers forever. This action cannot be undone.',
    cancel: 'Cancel',
    maxCarwashPerMonth: 'Monthly wash limit',
    startHour: 'Min hour',
    endHour: 'Max hour',
    days: 'Days',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    update: 'Update',
    permissions: 'Permissions'
  },
  transaction: {
    transactions: 'Transactions',
    // eslint-disable-next-line no-template-curly-in-string
    price: '${{price}}',
    paidByCard: 'Paid by card **** {{digits}}',
    download: 'Download',
    export: 'Export CSV',
    noTransactions: 'No transactions'
  },
  billing: {
    paymentMethod: 'Payment method',
    update: 'Update',
    cardNumber: 'Card number',
    name: 'Name on card',
    expirationDate: 'Expiration date (MM/YY)',
    cvv: 'CVV',
    currentCard: 'Current credit card,',
    updateCard: 'Update the credit card',
    required: 'This field is required',
    invalid: 'Invalid field',
    modifiedCreditCardSuccessTitle: 'Credit card updated',
    modifiedCreditCardSuccessMessage: 'Your credit card has been updated successfully.',
    modifiedCreditCardErrorTitle: 'Credit card error',
    modifiedCreditCardErrorMessage: 'Your credit card update failed to be processed.'
  },
  logout: {
    loggingOut: 'Please wait...',
    loggingOutMessage: 'We are logging you out, you\'ll be automatically redirected'
  },
  pagination: {
    previous: 'Previous',
    next: 'Next',
    showing: 'Showing ',
    to: ' to ',
    of: ' of ',
    results: ' results'
  },
  //TABLE
  network: {
    "list-headers" : {
      "brand.name": "Name",
      "address": "address",
      "codes": "codes",
      "alertFr": "alertFr",
      "note": "note",
      "active": "active"
      

    }
  },
  //dropdown
  dropdown : {

  }
};


export default en;
