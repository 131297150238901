import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/actions/User";

const Logout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('logout');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser());
    setTimeout(() => navigate('/'), 1500);
  }, [dispatch, navigate]);

  return (
    <>
      <main
        className="min-h-full bg-cover bg-top sm:bg-top"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1605164599901-f8a1464a2c87?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3570&q=80")',
        }}
      >
        <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
            {t('loggingOut')}
          </h1>
          <p className="mt-2 text-lg font-medium text-white text-opacity-50">
            {t('loggingOutMessage')}
          </p>
        </div>
      </main>
    </>
  )
}

export default Logout;
