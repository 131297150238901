export const GET_STORES = 'GET_STORES';
export const SET_STORES = 'SET_STORES';
export const GET_REMAINING = 'GET_REMAINING';
export const SET_REMAINING = 'SET_REMAINING';
export const GET_CARWASH_TYPE = 'GET_CARWASH_TYPE';
export const SET_CARWASH_TYPE = 'SET_CARWASH_TYPE';
export const ADD_CURRENT_BUYING = 'ADD_CURRENT_BUYING';
export const MODIFY_AMOUNT_BUYING = 'MODIFY_AMOUNT_BUYING'


