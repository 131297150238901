import { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (refs, callBack) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside (event) {
      let references = [];
      if (!Array.isArray(refs)) {
        references.push(refs);
      } else {
        references = refs;
      }

      if (references.every(ref => ref.current && !ref.current.contains(event.target))) {
        callBack();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, callBack]);
};

export default useOutsideAlerter;
