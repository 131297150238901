import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import InputMask from 'react-input-mask';
import { CodeIcon } from '@heroicons/react/solid'
import NeatcarButton from "../../components/NeatcarButton";
import { getEmployeeTransactions } from "../../store/actions/Transactions";
import type { Transaction } from "../../models/Transaction";
import Paginator from "../../components/Paginator";
import TransactionRow from "../../components/TransactionRow";
import LoadingIndicator from "../../components/LoadingIndicator";
import { getCSV } from "../../services/transactions";
import fileDownload from "js-file-download";

const Member = () => {
  const { register, handleSubmit } = useForm();
  const [, setLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation(['member', 'transaction']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { business, selectedEmployee } = useSelector(store => store.business);
  const {
    selectedEmployeeTransactionsLoading,
    selectedEmployeeTransactions,
    selectedEmployeeTransactionsPage,
    selectedEmployeeTransactionsPageSize,
    selectedEmployeeTransactionsTotal } = useSelector(store => store.transactions);

  useEffect(() => {
    if (!selectedEmployee || !business) {
      navigate('/team');
    } else {
      dispatch(getEmployeeTransactions(business.id, selectedEmployee.id, page));
    }
  }, [selectedEmployee, business, page, navigate, dispatch]);

  const onSubmit = (data) => {
    console.log(data);
    setLoading(true);
    // dispatch(updateMember(data.email, data.password, data.rememberMe));
  }

  const exportCsv = () => {
    setCsvLoading(true);
    getCSV(business.id, selectedEmployee.id)
      .subscribe(data => {
        fileDownload(data, `${selectedEmployee.user.lastName}-${selectedEmployee.user.firstName}-transactions.csv`);
        setCsvLoading(false);
      });
  };

  if (!selectedEmployee) {
    return <></>;
  }

  return (
    <div className="flex flex-row gap-x-8">
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 h-fit" style={{ width: '24rem' }}>
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between h-12">
            <span className="text-lg leading-6 font-medium text-gray-900">{t('permissions')}</span>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
            <div>
              <label htmlFor="maxCarwashPerMonth" className="block text-sm font-medium text-gray-700">
                {t('maxCarwashPerMonth')}
              </label>
            </div>
            <div className="mt-1">
              <input
                {...register("maxCarwashPerMonth", { valueAsNumber: true, required: { value: true, message: t('required') } })}
                defaultValue={selectedEmployee.maxCarwashPerMonth}
                type="number"
                name="maxCarwashPerMonth"
                id="maxCarwashPerMonth"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-16 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="py-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
            <div>
              <label htmlFor="startHour" className="block text-sm font-medium text-gray-700">
                {t('startHour')}
              </label>
            </div>
            <div>
              <InputMask
                {...register("startHour", { required: { value: true, message: t('required') } })}
                defaultValue={selectedEmployee.startHour}
                mask="ab:cd" maskChar="_"
                formatChars={
                  {
                    'a': '[0-2]',
                    'b': '[0-3]',
                    'c': '[0-5]',
                    'd': '[0-9]'
                  }
                }
                type="text"
                name="startHour"
                id="startHour"
                className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-20 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="py-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
            <div>
              <label htmlFor="endHour" className="block text-sm font-medium text-gray-700">
                {t('endHour')}
              </label>
            </div>
            <div>
              <InputMask
                {...register("endHour", { required: { value: true, message: t('required') } })}
                defaultValue={selectedEmployee.endHour}
                mask="ab:cd" maskChar="_"
                formatChars={
                  {
                    'a': '[0-2]',
                    'b': '[0-3]',
                    'c': '[0-5]',
                    'd': '[0-9]'
                  }
                }
                type="text"
                name="endHour"
                id="endHour"
                className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-20 sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="py-5 border-b border-gray-200 sm:flex sm:flex-col">
            <div>
              <label htmlFor="days" className="block text-sm font-medium text-gray-700">
                {t('days')}
              </label>
            </div>

            <div className="mt-4 grid grid-cols-3 gap-4">
              {['monday',
                'wednesday',
                'friday',
                'tuesday',
                'thursday',
                'saturday',
                '0', '',
                'sunday'].map(d => (
                <div key={d} className="relative flex items-start">
                  {!!d && d !== '0' && (<>
                    <div className="flex items-center h-5">
                      <input
                        {...register(d)}
                        defaultChecked={selectedEmployee[d]}
                        id={d}
                        name={d}
                        type="checkbox"
                        className="focus:ring-neatcar h-4 w-4 text-neatcar border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor={d} className="font-medium text-gray-700">
                        {t(d)}
                      </label>
                    </div>
                  </>)}
                </div>
              ))}
            </div>
          </div>

          <div className="mt-4 flex justify-end justify-items-end items-end">
            <div className="w-32">
              <NeatcarButton
                type={'submit'}
                loading={false}
                disabled={true}
                title={t('update')}
              />
            </div>
          </div>
        </form>
      </div>

      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 flex-1">
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3
            className="text-lg leading-6 font-medium text-gray-900">{t('transaction:transactions')}</h3>
          <div className="flex mt-3 sm:mt-0 sm:ml-4 gap-x-2">

            <NeatcarButton
              className="min-w-32"
              onClick={() => exportCsv()}
              loading={csvLoading}
              inverse
              type="button"
              title={t('transaction:export')}/>

            <NeatcarButton
              onClick={() => void 0}
              disabled={true}
              type="button"
              title={t('transaction:download')}/>
          </div>
        </div>

        {selectedEmployeeTransactionsLoading && (
          <div className={"flex flex-col justify-center items-center text-neatcarGray h-96"}>
            <div className={"h-12"}>
              <LoadingIndicator />
            </div>
          </div>
        )}
        {!selectedEmployeeTransactionsLoading && selectedEmployeeTransactions.map((tr: Transaction) => (
          <TransactionRow key={tr.id} transaction={tr}  businessId={business.id}/>
        ))}
        {!selectedEmployeeTransactionsLoading && selectedEmployeeTransactions.length === 0 && (
          <div className={"flex flex-col justify-center items-center text-neatcarGray h-96"}>
            <CodeIcon className={"h-24"}/>
            <div>{t('transaction:noTransactions')}</div>
          </div>
        )}
        <Paginator currentPage={selectedEmployeeTransactionsPage} itemsPerPage={selectedEmployeeTransactionsPageSize} totalItems={selectedEmployeeTransactionsTotal} newPage={(page) => setPage(page)} />
      </div>
    </div>
  )
};

export default Member;
