import { SELECT_EMPLOYEE, SET_BUSINESS, SET_EMPLOYEES, SET_CURRENT_CARD, SET_CARD_LOADING } from '../types/Business';
import { LOGOUT } from "../types/Session";

const initialState = {
  business: undefined,
  employees: [],
  selectedEmployee: undefined,
  currentCard: undefined,
  cardLoading: false
};

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SET_BUSINESS:
      return {
        ...state,
        business: action.payload,
      };
    case SET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };
    case SELECT_EMPLOYEE:
      return {
        ...state,
        selectedEmployee: action.payload
      };
    case SET_CARD_LOADING:
      return {
        ...state,
        cardLoading: action.payload,
      };
    case SET_CURRENT_CARD:
      return {
        ...state,
        cardLoading: false,
        currentCard: action.payload
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
