"use client"
import * as React from "react"
import { useState } from "react"; // Import useState hook
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  ColumnDef,
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table"

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover"



import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Checkbox } from "components/ui/checkbox"
import TripleToggleSwitch from "components/tripleToggle/triple"

import NeatcarButton from "../../components/NeatcarButton";
import { addToCurrentBuying } from 'store/actions/Support';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuCheckboxItem, DropdownMenuContent } from "components/ui/dropdown-menu";
import { SlidersHorizontal,TableProperties    } from 'lucide-react';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

export function StationsTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [csvLoading, setCsvLoading] = useState(false);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({'Rating':false})

  const dispatch = useDispatch();


  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,

    state: {
      sorting,
      columnFilters,
      rowSelection,
      columnVisibility

    },
    meta: {
      isEditDialogOpen,
      onOpenCard: () => setIsEditDialogOpen(true)
    }
  })


  const labels = {
    left: {
      title: "All",
      value: "All"
    },
    right: {
      title: "Yes",
      value: "Yes"
    },
    center: {
      title: "No",
      value: "No"
    }
  };

  const localLabels = {
    left: {
      title: "All",
      value: "All"
    },
    right: {
      title: "Local",
      value: "Local"
    },
    center: {
      title: "Network",
      value: "Network"
    }
  };



  return (
    <div className="rounded-md border">




      <div className="flex justify-between p-3">
        <div>
          <div className="pl-3 max-w-sm">
            <Input

              placeholder="Search"
              value={table.getColumn('Address')?.getFilterValue() as string || ""}
              onChange={(ev) => {
                table.getColumn('Address')?.setFilterValue(ev.target.value)

              }}
            />
          </div>
        </div>


        <div className="flex">
          <div className="px-3">
            {Object.keys(rowSelection).length > 0 && (
              <NeatcarButton
                className="min-w-32"
                onClick={() => { dispatch(addToCurrentBuying(table.getSelectedRowModel().rows.map(object => object.original))) }}
                loading={csvLoading}
                inverse
                type="button"
                title={'Add to buy'}
              />
            )}
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger>
              
                <TableProperties strokeWidth='1px'></TableProperties>
              
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table.getAllColumns().filter(column => column.getCanHide()).map(column => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value: boolean) => {
                      column.toggleVisibility(!!value)
                    }}
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
            </DropdownMenuContent>

          </DropdownMenu>

          <div>
            <Popover >
              <PopoverTrigger className="p-2"><SlidersHorizontal strokeWidth='1px'/></PopoverTrigger>
              <PopoverContent>


                <div className="grid justify-items-stretch">

                  <div className="">
                    <TripleToggleSwitch
                      labels={labels}
                      title={"Alert"}
                      switchPosition={table.getColumn('Alert')?.getFilterValue() || "left"}
                      onChange={(value) => {
                        table.getColumn('Alert')?.setFilterValue(value)
                      }}
                    />
                  </div>



                  <div className="pt-3">
                    <TripleToggleSwitch

                      labels={localLabels}
                      title={"Local"}
                      switchPosition={table.getColumn('Local Codes')?.getFilterValue() || "left"}
                      onChange={(value2) => {
                        table.getColumn('Local Codes')?.setFilterValue(value2)
                      }}
                    />
                  </div>


                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        
      </div>

      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="flex items-center justify-end space-x-2 py-4 pr-8">
        <div className="flex-1 pl-3 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length > 0 ?
            `${table.getFilteredSelectedRowModel().rows.length} of ${table.getFilteredRowModel().rows.length} selected` : ''}
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>

    </div>
  )
}
