import AxiosInstance from "./api";
import { map } from "rxjs";

export function authenticate (email, password, rememberMe) {
  const data = JSON.stringify({ email, password, rememberMe });
  return AxiosInstance.post('api/v1/user/token', data);
}

export function logout () {
  return AxiosInstance.delete('api/v1/user/token');
}

export function getCurrentUser () {
  return AxiosInstance.get('api/v1/user').pipe(
    map(res => res.data.data.user)
  );
}

export function getRoles () {
  return AxiosInstance.get('api/v1/user/role').pipe(
    map(res => res.data.data)
  );
}
