import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Paginator = ({
                     currentPage,
                     itemsPerPage,
                     totalItems,
                     newPage = () => void 0,
                   }) => {
  const [nextPage, setNextPage] = useState(-1);
  const [previousPage, setPreviousPage] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [startItem, setStartItem] = useState(0);
  const [endItem, setEndItem] = useState(0);
  const { t } = useTranslation('pagination');

  useEffect(() => {
    const _totalPages = Math.trunc(totalItems / itemsPerPage) + 1;
    setTotalPages(_totalPages);
    setNextPage(_totalPages > currentPage ? currentPage + 1 : -1);
    setPreviousPage(currentPage > 1 ? currentPage - 1 : -1);
    setStartItem((currentPage - 1) * itemsPerPage + 1);
    setEndItem(currentPage * itemsPerPage > totalItems ? totalItems : currentPage * itemsPerPage);
  }, [totalItems, itemsPerPage, currentPage]);

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          disabled={previousPage === -1}
          onClick={() => newPage(previousPage)}
        >
          {t('previous')}
        </button>
        <button
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          disabled={nextPage === -1}
          onClick={() => newPage(nextPage)}
        >
          {t('next')}
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {t('showing')}<span className="font-medium">{startItem}</span>{t('to')}<span className="font-medium">{endItem}</span>{t('of')}
            <span className="font-medium">{totalItems}</span>{t('results')}
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              disabled={previousPage < 1}
              onClick={() => newPage(1)}
            >
              <span className="sr-only">Previous</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              disabled={previousPage < 1}
              onClick={() => newPage(previousPage)}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {previousPage > 0 && (<button
              aria-current="page"
              className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              onClick={() => newPage(previousPage)}
            >
              {previousPage}
            </button>)}
            <button
              className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              {currentPage}
            </button>
            {nextPage > 0 && nextPage <= totalPages && (<button
              className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
              onClick={() => newPage(nextPage)}
            >
              {nextPage}
            </button>)}
            <button
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              disabled={nextPage === -1}
              onClick={() => newPage(nextPage)}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              disabled={nextPage === -1}
              onClick={() => newPage(totalPages)}
            >
              <span className="sr-only">Next</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
};

export default Paginator;
