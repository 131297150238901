import {
  SET_STORES,
  GET_STORES,
  GET_REMAINING,
  SET_REMAINING,
  GET_CARWASH_TYPE,
  SET_CARWASH_TYPE,
  ADD_CURRENT_BUYING,
  MODIFY_AMOUNT_BUYING
} from '../types/Support';
import { LOGOUT } from "../types/Session";

const initialState = {
  storesLoading: false,
  stores: [],

  carwashRemaningLoading: false,
  carwashRemaining: [],

  carwashTypeLoading: false,
  carwashType: [],

  currentBuying: []
};

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case GET_STORES:
      return {
        ...state,
        storesLoading: true,
      }
    case SET_STORES:
      return {
        ...state,
        stores: action.payload,
        storesLoading: false

        
      };

      case GET_REMAINING:
      return {
        ...state,
        carwashRemaningLoading: true,
      }
    case SET_REMAINING:
      return {
        ...state,
        
        carwashRemaining: action.payload.stores,
        carwashRemaningLoading: false,
        
      };

      
      case GET_CARWASH_TYPE:
      return {
        ...state,
        carwashTypeLoading: true,
      }
    case SET_CARWASH_TYPE:
      return {
        ...state,
        
        carwashType: action.payload.storeCarwashTypes,
        carwashTypeLoading: false
        
      };

      case ADD_CURRENT_BUYING:
        
    

      return { 
        ...state,
        currentBuying: state.currentBuying.concat(
          action.payload.map(row => ({
            ...row,
            amountBuying: 0 // Set the initial value for amountBuying
          })).filter(row => !state.currentBuying.find(object => object.id == row.id && object.routeName == row.routeName))
        )
      };
    
      case MODIFY_AMOUNT_BUYING:
        const { storeId, routeName, newValue } = action.payload;
        return {
          ...state,
          currentBuying: state.currentBuying.map(item => {
            if (item.storeId === storeId && item.routeName === routeName) {
              console.log(item)

              return {
                ...item,
                amountBuying: newValue
              };
            }
            return item;
          })
        };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
