import { PUSH_NOTIFICATION, POP_NOTIFICATION } from '../types/Notifications';
import { LOGOUT } from "../types/Session";

const initialState = {
  notifications: []
};

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case PUSH_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case POP_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications].slice(1)
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
