import React from 'react';
import { Outlet } from "react-router-dom";

const BaseLayout = () => {
  return (
    <div className={'h-full'}>
      <main><Outlet/></main>
    </div>
  );
};

export default BaseLayout;
