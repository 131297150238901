import React, { useState } from 'react';
import { ChevronRightIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import NeatcarButton from "../NeatcarButton";
import { removeEmployee } from "../../store/actions/Business";
import NeatcarModal from "../NeatcarModal";
import { ExclamationIcon } from "@heroicons/react/outline";

const EmployeeHeader = () => {
  const [showRemoveUser, setShowRemoveUser] = useState(false);
  const { selectedEmployee } = useSelector(store => store.business);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeUser = () => {
    dispatch(removeEmployee(selectedEmployee));
    navigate('/team');
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-row justify-between">
      <Helmet>
        <title>{`${selectedEmployee?.user.firstName} ${selectedEmployee?.user.lastName} | Neatcar`}</title>
      </Helmet>
      <div>
      <div className="flex flex space-x-1 text-white align-items-center">
        <Link to={'/team'} className="opacity-50">{t('menu:team')}</Link>
        <ChevronRightIcon className="opacity-80" height={24} />
        <div className="opacity-50">{`${selectedEmployee?.user.firstName} ${selectedEmployee?.user.lastName}`}</div>
      </div>
      <h1 className="text-3xl font-bold text-white mt-3">{`${selectedEmployee?.user.firstName} ${selectedEmployee?.user.lastName}`}</h1>
      </div>
      <div className="mt-3 sm:mt-0 sm:ml-4 self-end">
        <NeatcarButton
          className="bg-opacity-20 text-white"
          inverse={true}
          onClick={() => setShowRemoveUser(true)}
          type="button"
          title={t('member:delete')}/>
      </div>
      <NeatcarModal
        visible={showRemoveUser}
        onClose={() => setShowRemoveUser(false)}
        actionText={t('member:delete')}
        cancelText={t('member:cancel')}
        actionColor={'bg-error hover:bg-red-700 focus:ring-red-500'}
        onValidate={() => removeUser()}>
        <>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {t('member:deleteTitle')}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('member:deleteText')}
                </p>
              </div>
            </div>
          </div>
        </>
      </NeatcarModal>
    </div>
  );
};

export default EmployeeHeader;
