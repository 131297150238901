import dayjs from "dayjs";
import { CheckCircleIcon } from "@heroicons/react/outline";
import facture from "../assets/images/facture.png";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { getReceipt } from "../services/transactions";

const TransactionRow = ({ transaction, businessId }) => {
  const { t } = useTranslation(['transaction']);

  const downloadBill = () => {
    getReceipt(businessId, transaction.id)
      .subscribe(url => window.open(url,'_blank'));
  }

  return (
    <div key={transaction.id} className="flex flex-row justify-between items-center mt-2 pb-2 border-b h-12">
      <div className="items-center flex flex-row gap-x-4">
        <img src={transaction.store.brand.smallLogo} alt={transaction.store.brand.name} className="w-10"/>
        <div className="flex flex-col gap-y-1">
          <div className="text-neatcar text-xs font-bold">{dayjs(transaction.createdAt, 'YYYY-MM-dd HH:mm:ss').format('D MMMM YYYY (HH:mm)')}</div>
          <div className="text-neatcarGray text-xs">{`${transaction.store.address}${transaction.store.address2 ? ', ' + transaction.store.address2 : ''}, ${transaction.store.province}`}</div>
        </div>
      </div>
      <div className="flex flex-col gap-y-1">
        <div className="text-xs text-neatcar ">{t('transaction:price', { price: (transaction.pricePaid / 100).toFixed(2) })} + {t('transaction:price', { price: (transaction.taxPaid / 100).toFixed(2) })} ({t('transaction:price', { price: ((transaction.pricePaid + transaction.taxPaid) / 100).toFixed(2) })})</div>
        <div className="text-neatcarGray text-xs flex flex-row gap-x-1"><CheckCircleIcon className="text-success w-4"/>{t('transaction:paidByCard', { digits: transaction.last4})}</div>
      </div>
      <div className="cursor-pointer" onClick={() => downloadBill()}>
        <img src={facture} alt={'Bill'} className="w-6"/>
      </div>
    </div>
  );
}

export default TransactionRow;
