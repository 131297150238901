import {
  SET_EMPLOYEE_TRANSACTION,
  SET_BUSINESS_TRANSACTION,
  GET_EMPLOYEE_TRANSACTION,
  GET_BUSINESS_TRANSACTION
} from '../types/Transactions';
import { LOGOUT } from "../types/Session";

const initialState = {
  selectedEmployeeTransactionsLoading: false,
  selectedEmployeeTransactions: [],
  selectedEmployeeTransactionsPage: 1,
  selectedEmployeeTransactionsPageSize: 20,
  selectedEmployeeTransactionsTotal: 0,
  selectedBusinessTransactionsLoading: false,
  selectedBusinessTransactions: [],
  selectedBusinessTransactionsPage: 1,
  selectedBusinessTransactionsPageSize: 20,
  selectedBusinessTransactionsTotal: 0
};

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_TRANSACTION:
      return {
        ...state,
        selectedEmployeeTransactionsLoading: true,
      }
    case SET_EMPLOYEE_TRANSACTION:
      return {
        ...state,
        selectedEmployeeTransactionsLoading: false,
        selectedEmployeeTransactions: action.payload.transaction,
        selectedEmployeeTransactionsPage: action.payload.pagination.currentPage,
        selectedEmployeeTransactionsPageSize: action.payload.pagination.currentPageSize,
        selectedEmployeeTransactionsTotal: action.payload.pagination.numberOfRecords,
      };
    case GET_BUSINESS_TRANSACTION:
      return {
        ...state,
        selectedBusinessTransactionsLoading: true,
      }
    case SET_BUSINESS_TRANSACTION:
      return {
        ...state,
        selectedBusinessTransactionsLoading: false,
        selectedBusinessTransactions: action.payload.transaction,
        selectedBusinessTransactionsPage: action.payload.pagination.currentPage,
        selectedBusinessTransactionsPageSize: action.payload.pagination.currentPageSize,
        selectedBusinessTransactionsTotal: action.payload.pagination.numberOfRecords,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
