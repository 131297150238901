import {
  GET_STORES, 
  SET_STORES,
  GET_REMAINING,
  SET_REMAINING,
  GET_CARWASH_TYPE,
  SET_CARWASH_TYPE,
  ADD_CURRENT_BUYING,
  MODIFY_AMOUNT_BUYING
} from '../types/Support';
import { getStores,getRemainingCarwash, getCarwashType } from "../../services/support";

import { forkJoin, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {routesHardCoded} from '../../utils/routesHardCoded'
export const getStoresData = () => (dispatch) => {
  dispatch({ type: GET_STORES });

  forkJoin({
    stores: getStores(),
    remaining: getRemainingCarwash(),
    carwashType: getCarwashType(),
  })
  .pipe(
    tap(({ stores, remaining, carwashType }) => {
      //SET_REMAINING & SET_CARWASH_TYPE might have use somedays
      dispatch({ type: SET_REMAINING, payload: remaining });
      dispatch({ type: SET_CARWASH_TYPE, payload: carwashType });
     //merge data here
     const merged = stores.stores
        /* add carwash data*/
        .map(object => {
          const matched = carwashType.storeCarwashTypes.filter(element => element.storeId === object.id).map(type => {

            const carwashRemaingData = remaining.stores.find(carwash => carwash.id === type.storeId)
            const amount = carwashRemaingData[type.nameEn]

            return {
              ...type,
              amount: amount
            }

          })


          return {
            ...object,
            carwashTypes: matched
          };
        })
        /*add routes*/
        .map(store => {
          const matched = routesHardCoded.filter(element => element.storeId == store.id).map(route => {

            return {
              ...route
            }
          })

          return {
            ...store,
            routes: matched
          }
        })

        dispatch({ type: SET_STORES, payload: merged });

      
      

    
    }),
    catchError((error) => {
      // Handle error
      console.error('Error fetching data:', error);
      // Re-throw the error to propagate it further
      return throwError(() => new Error(error));
    })
  )
  .subscribe();
};

export const getRemainingCarwashData = () => (dispatch) => {
  dispatch({ type: GET_REMAINING });
  getRemainingCarwash().subscribe({
    next: (value) =>
      dispatch({ type: SET_REMAINING, payload: value })
  });
}

export const getCarwashTypeData = () => (dispatch) => {
  dispatch({ type: GET_CARWASH_TYPE });
  getCarwashType().subscribe({
    next: (value) =>
      dispatch({ type: SET_CARWASH_TYPE, payload: value })
  });
}



export const addToCurrentBuying = (route) => (dispatch) => {
  dispatch({ type: ADD_CURRENT_BUYING, payload: route });
};

export const modifyAmountBuying = (storeId, routeName, newValue) => ({
  type: MODIFY_AMOUNT_BUYING,
  payload: {
    storeId,
    routeName,
    newValue
  }
});