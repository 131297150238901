
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const ERROR = 'error';

export class Notification {
  constructor (type, title, message) {
    this.type = type;
    this.title = title;
    this.message = message;
  }
}

