import { PUSH_NOTIFICATION, POP_NOTIFICATION } from '../types/Notifications';

export const pushNotification = (notification) => (dispatch) => {
  dispatch({ type: PUSH_NOTIFICATION, payload: notification });
};


export const popNotification = () => (dispatch) => {
  dispatch({ type: POP_NOTIFICATION });
};
